import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["hide_index"]
  toggle(event) {

    let formData = new FormData()
    formData.append("device[hide_index]", this.hide_indexTarget.checked);

    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(this.data.get("update-url"), {
      body: formData,
      method: 'PATCH',
      dataType: 'script',
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken
      },
    })

      .then(function (response) {
        if (response.status != 204) {
          event.target.checked = !event.target.checked
        }
      })
  }
}
