import './devices.js'
import './device_cals.js'
import './triggerAlert.js'
import './datatables.js'
import './alarms.js'
import './clickable.js'
import './link.js'
import './gatewayMap.js'
//import './geoman.js'
//import './mycharts.js'
//import './scrollingTab.js'
//import './deviceChangeGroup.js'
//import './chosen.js'
//import './summary.js'
//import './summary_tabs.js'

//window.$ = window.jQuery = require('jquery'); // not sure if you need this at all
//window.Bootstrap = require('bootstrap');

$(document).on('turbolinks:load', function(){
  if(!window.reloadTimer) {
    var SECONDS = 450;
    window.reloadTimer=setTimeout(function(){
      console.log("Reload!");
      window.reloadTimer=undefined;
      Turbolinks.visit(location.toString(),{action: "replace"});
    }, SECONDS * 1000);
    document.addEventListener("turbolinks:visit",function()
    {clearTimeout(window.reloadTimer);window.reloadTimer=undefined;});
  }
});

$(document).on('turbolinks:load', function(){
  $('.carousel').carousel({
     interval: 5000
  });
});

function number_pages() {
  var vars={};
  var x=document.location.search.substring(1).split('&');
  for(var i in x) {var z=x[i].split('=',2);vars[z[0]] = unescape(z[1]);}
  var x=['frompage','topage','page','webpage','section','subsection','subsubsection'];
  for(var i in x) {
    var y = document.getElementsByClassName(x[i]);
    for(var j=0; j<y.length; ++j) y[j].textContent = vars[x[i]];
  }
}