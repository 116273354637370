import "core-js/stable";
import "regenerator-runtime/runtime";
window.jQuery = $;
window.$ = $;
import "cocoon-js";
import "../stylesheets/application";
import "./bootstrap_custom.js";
import "controllers";
import "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "leaflet.polyline.snakeanim";
import "chosen-js/chosen.css";
import "chosen-js";

require("custom");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("datatables.net-bs4");
require("chartkick").use(require("highcharts"));

import Highcharts from 'highcharts';
require("highcharts/modules/data")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/offline-exporting")(Highcharts)
require("highcharts/modules/map")(Highcharts)
window.Highcharts = Highcharts;
//require("chartkick")
//require("chart.js")
require.context("../images", true);

document.addEventListener("turbolinks:load", () => {
  $(".chosen-select").chosen({ width: "100%" });
});

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
});
