import { Controller } from "stimulus"
export default class extends Controller {
    static targets = [ "device1",
                       "device2",
                       "device3",
                       "device4",
                       "device5",
                       "device6",
                       "device7",
                       "device8",
                       "device9",
                       "device10",
                       "device11",
                       "device12"
                      ]
    toggle(event) {

      let formData = new FormData()
      formData.append("device_group[device1]", this.device1Target.checked);
      formData.append("device_group[device2]", this.device2Target.checked);
      formData.append("device_group[device3]", this.device3Target.checked);
      formData.append("device_group[device4]", this.device4Target.checked);
      formData.append("device_group[device5]", this.device5Target.checked);
      formData.append("device_group[device6]", this.device6Target.checked);
      formData.append("device_group[device7]", this.device7Target.checked);
      formData.append("device_group[device8]", this.device8Target.checked);
      formData.append("device_group[device9]", this.device9Target.checked);
      formData.append("device_group[device10]", this.device10Target.checked);
      formData.append("device_group[device11]", this.device11Target.checked);
      formData.append("device_group[device12]", this.device12Target.checked);

      const csrfToken = document.querySelector("[name='csrf-token']").content

      fetch(this.data.get("update-url"), {
        body: formData,
        method: 'PATCH',
        dataType: 'script',
        credentials: "include",
        headers: {
                  "X-CSRF-Token": csrfToken
                 },
      })

      .then(function(response) {
        if (response.status != 204) {
          event.target.checked = !event.target.checked
        }
      })
    }
}
