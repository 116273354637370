$(document).on("turbolinks:load", function () {
  //console.log("Is turbolinks loading!")
  let APIURL = "http://41.79.79.27/gateways.json";

    
  try {
    const map = L.map("mapid").setView([-26.27076, 28.112268], 5);
    
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZXJuZXN0MmV1Y2EiLCJhIjoiY2s2amNqcW00MDlsNDNrbXBkbXFxem5icSJ9.6mG5oqYkjLx0FJihHE6xOQ",
      {
        maxZoom: 20,
        minZoom: 3,
        attribution: `2020 Nvirosense`,
        //@ts-ignore
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
        "pk.eyJ1IjoiZXJuZXN0MmV1Y2EiLCJhIjoiY2s2amNqcW00MDlsNDNrbXBkbXFxem5icSJ9.6mG5oqYkjLx0FJihHE6xOQ",
      }
      ).addTo(map);
      
      L.control
      .scale({
        imperial: false,
        maxWidth: 300,
      })
      .addTo(map);
      
  let gpscoords = [];

  let markers = fetch(APIURL)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);

      for (let index = 0; index < data.gateways.length; index++) {
        const element = data.gateways[index];
        console.log("Loading Markers");

        let tempmarker = L.marker([element.latitude, element.longitude])
          .bindPopup(
            `Device:${element.name} <br> Device Description:${element.description}`
          )
        //   .on("click")
        //   .openPopup();
        gpscoords.push(tempmarker);

        if (index != 0 && data.gateways[index + 1] != undefined) {
          let polyline = L.polyline(
            [
              [element.latitude, element.longitude],
              [
                data.gateways[index + 1].latitude,
                data.gateways[index + 1].longitude,
              ],
            ],
            { color: "blue" }
          );
          gpscoords.push(polyline);
        }
        if (index == 0) {
          let polyline = L.polyline(
            [
              [element.latitude, element.longitude],
              [
                data.gateways[index + 1].latitude,
                data.gateways[index + 1].longitude,
              ],
            ],
            { color: "blue" }
          );
          gpscoords.push(polyline);
        }
      }

    })

    .catch(function (error) {
      // handle error
      console.log(error);
    })

    .finally(function () {
      let route = L.layerGroup(gpscoords, {
        snakingPause: 1000,
        followHead: false,
      });
      route.addTo(map).snakeIn();
    });
  } catch (error) {
     
  }



  function delay(delayInms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
  }
});
