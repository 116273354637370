$(document).on('turbolinks:load', function () {
  $('#device-up-datatable').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#device-up-datatable').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      { "data": "received_at" },
      { "data": "device_name" },
      { "data": "dev_eui_read" },
      { "data": "f_cnt" },
      { "data": "frequency" },
      { "data": "dr" },
      { "data": "name" },
      { "data": "rssi" },
      { "data": "flag_0" },
      { "data": "flag_1" }
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });
});


$(document).on('turbolinks:load', function () {
  $('#reading-datatable').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#reading-datatable').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      { "data": "received_at" },
      { "data": "device_name" },
      { "data": "dev_eui_read" },
      { "data": "message_type" },
      { "data": "options" },
      { "data": "a_total" },
      { "data": "b_total" },
      { "data": "c_total" },
      { "data": "d_total" },
      { "data": "e_total" },
      { "data": "f_total" },
      { "data": "g_total" },
      { "data": "h_total" },
      { "data": "battery_level" },
      { "data": "backlog_cnt" },
      { "data": "alarm_cnt" },
      { "data": "eval_exception" },
      { "data": "eval_alarm" }
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });
});
